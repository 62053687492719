import React from "react"

import Layout from "./components/Layout"
import SEO from "./components/Seo"
import Resume from "./components/svgComponent/Resume"

import "../utils/normalize.css"
import "../utils/css/screen.css"

function ResumePage() {
  return (
    <Layout>
      <SEO
        title="cp - resume"
        keywords={["resume", "frontend-developement", "ReactJs", "JavaScript"]}
      />
      <div style={{ width: "100vw", textAlign: "center" }}>
        <Resume />
      </div>
    </Layout>
  )
}

export default ResumePage
